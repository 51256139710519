import React, { useEffect, useState } from "react";

interface ErrorFallbackProps {
  error: unknown;
  resetErrorBoundary: () => void;
  className?: string;
}

const ErrorFallback: React.FC<ErrorFallbackProps> = ({
  error,
  resetErrorBoundary,
  className,
}) => {
  const [isOnline, setIsOnline] = useState<boolean>(navigator.onLine);
  const [networkChange, setNetworkChange] = useState<boolean>(false);

  const reloadPage = () => {
    resetErrorBoundary();
    window.location.reload();
  };

  useEffect(() => {
    const onlineHandler = () => {
      setIsOnline(true);
      setNetworkChange(true);
    };
    const offlineHandler = () => {
      setIsOnline(false);
      setNetworkChange(true);
    };

    window.addEventListener("online", onlineHandler);
    window.addEventListener("offline", offlineHandler);

    return () => {
      window.removeEventListener("online", onlineHandler);
      window.removeEventListener("offline", offlineHandler);
    };
  }, []);

  useEffect(() => {
    if (networkChange && isOnline) {
      reloadPage();
    }
  }, [isOnline, networkChange]);

  const isDevMode = process.env.VITE_DEPLOYMENT_MODE === "development";

  const errorMessage = error instanceof Error ? error.message : String(error);

  return (
    <div role="alert" className={className || "w-full pr-4 pl-11 mt-20"}>
      <h1 className="mb-5 font-medium text-28 md:text-40">
        Something Went Wrong
      </h1>
      <div>
        <h3 className="mb-10 font-light text-21 whitespace-pre-wrap">
          {isOnline
            ? networkChange
              ? "Please wait, reloading..."
              : "Sorry, Something went wrong. Please try again."
            : "No internet connection available. \nPlease connect to internet first and then try again."}
        </h3>
        {!networkChange && isOnline && (
          <button
            type="button"
            className="py-2 px-4 bg-primary_colour text-white"
            onClick={reloadPage}
          >
            Try again
          </button>
        )}
      </div>
      {isDevMode && (
        <div className="mt-12 border-t pt-6">
          <h5 className="mb-4 underline text-21">Error Hint:</h5>
          <pre>{errorMessage}</pre>
        </div>
      )}
    </div>
  );
};

export default ErrorFallback;
