import { useEffect, useMemo } from "react";
import axios from "axios";
// import { useAtom } from "jotai";
// import { organizationUuidAtom } from "@/atom/atom";
import { API_BASE_URL } from "../config/api";
import { organizationAtom } from "../atom/atom";
import { useAtom } from "jotai";

const EXCLUDE_ORG_UUID_ROUTES = ["/api/auth/login"];

const useAxiosPublic = () => {
  const [organization] = useAtom(organizationAtom);

  const organizationUuid = organization?.uuid;

  const publicAxiosInstance = useMemo(
    () =>
      axios.create({
        baseURL: API_BASE_URL,
        timeout: 10000,
        headers: {
          "Content-type": "application/json",
        },
      }),
    [],
  );

  if (!organizationUuid) {
    console.log("Organization not found");
    return publicAxiosInstance;
  }
  useEffect(() => {
    const accessToken = localStorage.getItem("token");
    const requestIntercept = publicAxiosInstance.interceptors.request.use(
      (config) => {
        if (!config.url) return config;

        if (!config.headers["Authorization"]) {
          config.headers["Authorization"] = `Bearer ${accessToken}`;
        }

        if (config.url && config.url.includes("/api/auth/user")) {
          config.baseURL = `${API_BASE_URL}/api/public/organization/${organizationUuid}`;
          return config;
        }

        if (config.url && config.url.includes("/forgot-password")) {
          config.baseURL = `${API_BASE_URL}/api/auth`;
          return config;
        }

        if (config.url && config.url.includes("/reset-password")) {
          config.baseURL = `${API_BASE_URL}/api/auth`;
          return config;
        }

        if (config.url && config.url.includes("/send-verification-email")) {
          config.baseURL = `${API_BASE_URL}/api/auth`;
          return config;
        }

        if (config.url && config.url.includes("/verify-email")) {
          config.baseURL = `${API_BASE_URL}/api/auth`;
          return config;
        }

        const shouldExcludeOrgUuid = EXCLUDE_ORG_UUID_ROUTES.some((route) =>
          config.url?.startsWith(route),
        );

        if (!shouldExcludeOrgUuid && organizationUuid) {
          // Handle organization-specific routes
          if (config.url.startsWith("/organization/")) {
            // If querying a specific organization, don't prepend the organizationUuid
            config.url = `/api/public${config.url}`;
          } else {
            // For other routes, prepend the organization UUID
            config.url = `/api/public/organization/${organizationUuid}${config.url}`;
          }
          config.baseURL = API_BASE_URL; // Reset baseURL to avoid duplication
        }

        return config;
      },
      (error) => Promise.reject(error),
    );

    return () => {
      publicAxiosInstance.interceptors.request.eject(requestIntercept);
    };
  }, [publicAxiosInstance, organizationUuid]);

  return publicAxiosInstance;
};

export default useAxiosPublic;
