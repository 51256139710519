import { useEffect, useRef, useState } from "react";

const PrivacyPolicy = () => {
  const elementRef = useRef<HTMLDivElement>(null);
  const [isScrolled, setIsScrolled] = useState<boolean>(false);
  const [bottomY, setBottomY] = useState(0);

  console.log(bottomY);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > bottomY) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const getElementBottomY = () => {
      if (elementRef.current) {
        const rect = elementRef.current.getBoundingClientRect();
        setBottomY(rect.bottom);
      }
    };
    getElementBottomY();

    window.addEventListener("resize", getElementBottomY);

    return () => {
      window.removeEventListener("resize", getElementBottomY);
    };
  }, []);

  return (
    <div className="w-full min-h-screen pb-8 focus:outline-none">
      <div
        ref={elementRef}
        className={`fixed w-full ${isScrolled && "shadow-md"} bg-white transition-[background-color,box-shadow] duration-300 ease-out lg:pr-24`}
      >
        <div className="max-w-7xl mx-auto py-4 px-3 lg:px-0">
          <h1
            className={`text-3xl font-bold transition-[background-color,box-shadow] duration-300 ease-out ${
              isScrolled && "text-xl"
            } lg:pl-8`}
          >
            Privacy Policy
          </h1>
          {!isScrolled && (
            <p className="text-gray-500 mt-2 lg:pl-8">
              Last Updated: September 2024
            </p>
          )}
        </div>
      </div>
      <hr />
      <div className="max-w-7xl mx-auto px-3 lg:px-8 mt-32">
        <div className="mt-8 space-y-6">
          <p>
            These Terms and Conditions govern your use of our website and
            services. Please read them carefully.
          </p>

          <section>
            <h2 className="text-xl font-semibold mb-2">
              1. Acceptance of Terms
            </h2>
            <p>
              By using our services, you agree to these Terms and Conditions,
              along with any other policies referenced herein. If you do not
              agree with any part of these Terms, you must stop using the
              service immediately.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-2">2. Changes to Terms</h2>
            <p>
              We reserve the right to modify these Terms at any time. Any
              changes will be effective immediately upon posting. It is your
              responsibility to review the Terms regularly. Continued use of the
              services constitutes acceptance of any revised Terms.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-2">3. Use of Services</h2>
            <p>
              You agree to use the services in accordance with all applicable
              laws and regulations. You may not:
            </p>
            <ul className="list-disc pl-5 mt-2 space-y-2">
              <li>
                <strong>Engage in unlawful activities:</strong> You may not use
                the services for any illegal purposes.
              </li>
              <li>
                <strong>Disrupt the service:</strong> Attempting to hack,
                destabilize, or disrupt the functioning of the website or
                services is strictly prohibited.
              </li>
            </ul>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-2">
              4. Intellectual Property
            </h2>
            <p>
              All content, logos, designs, and trademarks displayed on our
              website are the intellectual property of [Company Name]. You may
              not use any content without prior written permission.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-2">
              5. Limitation of Liability
            </h2>
            <p>
              To the maximum extent permitted by law, [Company Name] is not
              liable for any damages arising from your use of the website or
              services.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-2">6. Governing Law</h2>
            <p>
              These Terms are governed by the laws of [Jurisdiction]. Any
              disputes will be resolved in accordance with these laws.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-2">7. User Accounts</h2>
            <p>
              You may be required to create an account to access certain
              features of our services. You agree to provide accurate and
              complete information, and to keep this information updated. You
              are responsible for safeguarding your account login credentials.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-2">8. Privacy Policy</h2>
            <p>
              Your use of our services is also governed by our Privacy Policy,
              which outlines how we collect, use, and protect your personal
              information. Please review our Privacy Policy to understand our
              practices.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-2">9. Termination</h2>
            <p>
              We reserve the right to suspend or terminate your access to the
              services at our discretion, without notice, if you violate these
              Terms or engage in activities that harm the services or other
              users.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-2">10. Indemnification</h2>
            <p>
              You agree to indemnify and hold [Company Name] harmless from any
              claims, damages, or expenses (including legal fees) arising out of
              your use of the services or violation of these Terms.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-2">
              11. Disclaimer of Warranties
            </h2>
            <p>
              Our services are provided "as is" and without any warranties of
              any kind, whether express or implied. We do not guarantee that the
              services will be uninterrupted or error-free, or that any defects
              will be corrected.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-2">
              12. Contact Information
            </h2>
            <p>
              If you have any questions or concerns about these Terms, please
              contact us at [Contact Information].
            </p>
          </section>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
