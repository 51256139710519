import { CartSessionType } from "@/types/sessionTypes";
import { IUserDetails } from "@/types/userTypes";
import { atom } from "jotai";
import { OrganizationType } from "../types/organizationTypes";

export const isLoggedInAtom = atom(false);

export const cartAtom = atom<CartSessionType[]>([]);
export const selectedAttendeeAtom = atom<string[]>([]);

export const userDetailsAtom = atom<IUserDetails | null>(null);

export const showOptionsToIncrementAtom = atom({});

export const paymentCountDownTimerAtom = atom<number | null>(null);

export const headerHeightAtom = atom(0);
export const footerHeightAtom = atom(0);

export const userSessionsToBookAtom = atom<
  { session: string; attendee: string }[]
>([]);

export const currentCheckoutFormStepAtom = atom(0);

export const currentUserAccessDetailsAtom =
  atom<CurrentUserAccessDetailsAtomType | null>(null);

export type CurrentUserAccessDetailsAtomType = {
  hasFullAccess: boolean;
  hasAccessToRegister: boolean;
};

export const organizationAtom = atom<OrganizationType | null>(null);
