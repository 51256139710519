import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import "./index.css";
import { Toaster } from "./components/ui/toaster.tsx";
import * as Sentry from "@sentry/react";
import { DefaultOptions, QueryClient, QueryClientProvider } from "react-query";
import ErrorFallback from "./components/ErrorFallback";

const defaultQueryOptions: DefaultOptions = {
  queries: {
    // refetchOnWindowFocus: false,
    // refetchOnMount: false,
    // refetchOnReconnect: false,
    // retry: 1,
    // staleTime: 5 * 60 * 1000,
  },
};

const queryClient = new QueryClient({
  defaultOptions: defaultQueryOptions,
});

const isProduction = process.env.VITE_DEPLOYMENT_MODE === "production";
const backendUrl = process.env.VITE_BACKEND_URL || "";

if (isProduction) {
  Sentry.init({
    dsn: process.env.VITE_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [backendUrl],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
} else {
  Sentry.init({
    dsn: process.env.VITE_SENTRY_DSN,
    release: `${process.env.VITE_APP_NAME}@${process.env.VITE_APP_VERSION}`,
    integrations: [Sentry.browserTracingIntegration()],
    tracesSampleRate: 0,
    enabled: false,
  });
}

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <Sentry.ErrorBoundary
      fallback={({ error, resetError }) => (
        <ErrorFallback error={error} resetErrorBoundary={resetError} />
      )}
    >
      <QueryClientProvider client={queryClient}>
        <App />
        <Toaster />
      </QueryClientProvider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
);
