import Spinner from "../Spinner";

const ScreenLoader = () => (
  <>
    <div className="flex flex-col justify-center items-center h-screen">
      <Spinner />
    </div>
  </>
);

export default ScreenLoader;
