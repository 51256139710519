import * as z from "zod";

export const OrganizationAuthSchema = z.object({
  email: z.string().email(),
  password: z.string().min(6),
  firstName: z.string().min(2, "First name is required"),
  lastName: z.string().min(2, "Last name is required"),
  name: z.string().min(1, "Organization name is required"),
  currency: z.string().min(3, "Currency is required"),
  accountUrl: z.string().min(3, "Url must contain atleast 3 characters"),
  gender: z.string().min(4),
  dateOfBirth: z.string().min(4),
  customerEmail: z.string().email(),
});


export const OrganizationManagementSchema = z.object({
  name: z.string().min(1, "Organization name is required"),
  heading: z.string().min(1, "Organization heading is required"),
  description: z.string().min(1, "Description is required"),
  customerEmail: z.string().nullable().optional(),
  imageUrl: z.string().nullable().optional(),
  currency: z.string().min(3, "Currency is required"),
  active: z.boolean(),
  activityDiscoveryType: z.enum(["DEFAULT", "BY_LOCATION"]),
});


export const SocialMediaLinkSchema = z.object({
  socialMediaType: z.string().min(1, "Social media type is required"),
  link: z.string().min(1, "Link is required"),
});
