import { API } from "@/config/api";
import { AxiosError } from "axios";
import useAxiosPublic from "./useAxiosPublic";

const usePublicAPIs = () => {
  const apiInstance = useAxiosPublic();
  const login = async (email: string, password: string) => {
    try {
      const response = await apiInstance.post(API.SIGN_IN, {
        email,
        password,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  interface RegisterData {
    person: {
      firstName: string;
      lastName: string;
      gender: string;
      dateOfBirth: string;
    };

    credentials: {
      email: string;
      password: string;
    };
  }

  const register = async (data: RegisterData) => {
    try {
      const response = await apiInstance.post(API.SIGN_UP, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const forgotPassword = async (email: string) => {
    try {
      const response = await apiInstance.post(API.FORGOT_PASSWORD, {
        email,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const resetPassword = async ({
    newPassword,
    token,
  }: {
    newPassword: string;
    token: string;
  }) => {
    try {
      const response = await apiInstance.post(API.RESET_PASSWORD, {
        newPassword,
        token,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const sendVerificationEmail = async (email: string) => {
    try {
      const response = await apiInstance.post(API.SEND_VERIFICATION_EMAIL, {
        email,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const verifyEmailCode = async ({
    email,
    verificationCode,
  }: {
    email: string;
    verificationCode: string;
  }) => {
    try {
      const response = await apiInstance.post(API.VERIFY_EMAIL, {
        email,
        verificationCode,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const getPublicVenues = async () => {
    try {
      const response = await apiInstance.get("/venue");
      return response.data;
    } catch (error: AxiosError | any) {
      throw error;
    }
  };
  const getPublicActivities = async ({ id }: { id?: string }) => {
    try {
      const url = id ? `/activity?venueUuid=${id}` : `/activity`;
      const response = await apiInstance.get(url);
      return response.data;
    } catch (error: AxiosError | any) {
      throw error;
    }
  };




  interface Organization {
    name: string;
    subDomain: string;
    customerEmail: string;
    currency: string;
    staffUser: {
      person: {
        firstName: string;
        lastName: string;
        gender: string;
        dateOfBirth: string;
      };
      credentials: {
        email: string;
        password: string;
      };
    };
  }
  const createOrganization = async (organization: Organization) => {
    try {
      const response = await apiInstance.post(
        "api/public/organization/",
        organization,
      );
      return response.data;
    } catch (error: AxiosError | any) {
      console.error("API Error:", error.response?.data || error.message);
      throw error;
    }
  };

  const getPublicOrganization = async ({ id }: { id: string }) => {
    try {
      const response = await apiInstance.get(`/organization/${id}`);
      return response.data;
    } catch (error: AxiosError | any) {
      throw error;
    }
  };

  const getOrganizationById = async ({ id }: { id: string }) => {
    try {
      const response = await apiInstance.get(`/organization/${id}`);
      return response.data;
    } catch (error: AxiosError | any) {
      throw error;
    }
  };

  const getActivityById = async ({ id }: { id: string }) => {
    try {
      const response = await apiInstance.get(`/activity/${id}`);
      return response.data;
    } catch (error: AxiosError | any) {
      throw error;
    }
  };

  const getSessionsForActivity = async ({ id }: { id: string }) => {
    try {
      const response = await apiInstance.get(`/activity/${id}/session`);
      return response.data;
    } catch (error: AxiosError | any) {
      throw error;
    }
  };

  const validateSessions = async (sessionUuidList: string[]) => {
    try {
      const response = await apiInstance.post(`${API.SESSIONS}/validate`, {
        sessionUuidList,
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const getAttendee = async () => {
    try {
      const response = await apiInstance.get(API.ATTENDEE);
      return response.data;
    } catch (error: AxiosError | any) {
      throw error;
    }
  };

  const createAttendee = async ({
    person,
    medicalConditions,
  }: {
    person: {
      firstName: string;
      lastName: string;
      gender: string;
      dateOfBirth: string;
    };
    medicalConditions: string[];
  }) => {
    try {
      const response = await apiInstance.post(API.ATTENDEE, {
        person,
        medicalConditions,
      });
      return response.data;
    } catch (error: AxiosError | any) {
      throw error;
    }
  };

  const updateAttendee = async ({
    person,
    medicalConditions,
    attendeeUuid,
  }: {
    person: {
      firstName: string;
      lastName: string;
      gender: string;
      dateOfBirth: string;
    };
    medicalConditions: string[];
    attendeeUuid: string;
  }) => {
    try {
      const response = await apiInstance.put(
        `${API.ATTENDEE}/${attendeeUuid}`,
        {
          person,
          medicalConditions,
        },
      );
      return response.data;
    } catch (error: AxiosError | any) {
      throw error;
    }
  };
  interface CreateBookingData {
    bookings: {
      session: string;
      attendee: string;
    }[];
  }

  const createBooking = async ({ bookings }: CreateBookingData) => {
    try {
      const response = await apiInstance.post(API.BOOKING_TXN, {
        bookings,
      });
      return response.data;
    } catch (error: AxiosError | any) {
      throw error;
    }
  };

  const getAllPublicBookings = async () => {
    try {
      const response = await apiInstance.get(API.GET_PUBLIC_BOOKINGS);
      return response.data;
    } catch (error: AxiosError | any) {
      throw error;
    }
  };

  interface CreateBookingUserData {
    person: {
      firstName: string;
      lastName: string;
    };
    credentials: {
      email: string;
      password: string;
    };
  }

  const createBookingUser = async (data: CreateBookingUserData) => {
    try {
      console.log("Data", data);

      const response = await apiInstance.post(API.USER, data);
      return response.data;
    } catch (error: AxiosError | any) {
      throw error;
    }
  };

  const cancelBooking = async ({ bookingId }: { bookingId: string }) => {
    try {
      const response = await apiInstance.post(
        `${API.GET_PUBLIC_BOOKINGS}/${bookingId}/cancel`,
      );
      return response.data;
    } catch (error: AxiosError | any) {
      throw error;
    }
  };

  const getClientSecretForCardPayment = async ({
    bookingTxnId,
  }: {
    bookingTxnId: string;
  }) => {
    try {
      const response = await apiInstance.post(
        `${API.BOOKING_TXN}/${bookingTxnId}/initiate`,
      );
      return response.data;
    } catch (error: AxiosError | any) {
      throw error;
    }
  };

  const fetchStripeConnectInfo = async () => {
    try {
      const response = await apiInstance.get("/stripe-connect-info");
      return response.data;
    } catch (error) {
      console.log("Error:", error);
      throw error;
    }
  };

  const voidBooking = async ({ bookingTxnId }: { bookingTxnId: string }) => {
    try {
      const response = await apiInstance.post(
        `${API.BOOKING_TXN}/${bookingTxnId}/void`,
      );
      return response.data;
    } catch (error: AxiosError | any) {
      throw error;
    }
  };
  // const response = await fetch(`/api/public/user/${user.uuid}`, {
  //   method: 'PUT',
  //   headers: {
  //     'Content-Type': 'application/json',
  //   },
  //   body: JSON.stringify({
  //     person: {
  //       firstName: data.firstName,
  //       lastName: data.lastName,
  //       gender: data.gender,
  //       dateOfBirth: data.dateOfBirth,
  //     },
  //   }),

  interface Person {
    firstName: string;
    lastName: string;
    gender: string;
    dateOfBirth: string;
  }

  const updateBookingUserProfile = async ({
    bookingUserUuid,
    bookingPerson,
  }: {
    bookingUserUuid: string;
    bookingPerson: Person;
  }) => {
    try {
      const response = await apiInstance.put(`/user/${bookingUserUuid}`, {
        person: bookingPerson,
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const updateBookingUserPassword = async ({
    bookingUserUuid,
    password,
    newPassword,
  }: {
    bookingUserUuid: string;
    password: string;
    newPassword: string;
  }) => {
    try {
      const response = await apiInstance.put(
        `/user/${bookingUserUuid}/password`,
        {
          oldPassword: password,
          newPassword,
        },
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const getOrganizationPaymentOptions = async (organizationUuid: string) => {
    try {
      const response = await apiInstance.get(
        `${API.ORGANIZATION}/${organizationUuid}/payment-option`,
      );
      return response.data;
    } catch (error: AxiosError | any) {
      throw error;
    }
  };

  return {
    login,
    register,
    forgotPassword,
    resetPassword,
    sendVerificationEmail,
    verifyEmailCode,
    getPublicActivities,
    getPublicOrganization,
    getActivityById,
    getSessionsForActivity,
    getPublicVenues,
    getOrganizationById,
    validateSessions,
    getAttendee,
    createAttendee,
    updateAttendee,
    createBooking,
    getAllPublicBookings,
    createOrganization,
    createBookingUser,
    cancelBooking,
    getClientSecretForCardPayment,
    fetchStripeConnectInfo,
    voidBooking,
    updateBookingUserProfile,
    updateBookingUserPassword,
    getOrganizationPaymentOptions,
  };
};

export default usePublicAPIs;
