import { BarChart, Calendar, CreditCard, Rocket } from "lucide-react";
import React from "react";

import { Link } from "react-router-dom";

const MainPage: React.FC = () => {
  return (
    <div className="bg-gradient-to-b from-blue-50 to-white text-gray-800 min-h-screen">
      {/* Hero Section */}
      <header className="container mx-auto px-4 py-16 text-center">
        <h1 className="text-5xl md:text-6xl font-bold mb-4 text-blue-600">
          Welcome to SpaceStation
        </h1>
        <p className="text-xl md:text-2xl mb-8 text-gray-600">
          Your journey to effortless online booking starts here
        </p>
        <Link
          to="/register"
          className="inline-block bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-6 rounded-full text-lg transition duration-300 shadow-lg"
        >
          Get Started Now
        </Link>
      </header>

      {/* Features Section */}
      <section className="container mx-auto px-4 py-16">
        <h2 className="text-3xl md:text-4xl font-bold text-center mb-12 text-blue-700">
          Why Choose SpaceStation?
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {[
            {
              icon: Rocket,
              title: "Easy-to-Use",
              description:
                "Simple and intuitive booking system for you and your customers",
            },
            {
              icon: Calendar,
              title: "Online Booking",
              description: "Enable customers to book and pay online 24/7",
            },
            {
              icon: CreditCard,
              title: "Secure Payments",
              description: "Safe and secure online payment processing",
            },
            {
              icon: BarChart,
              title: "Business Growth",
              description: "Support your growth with built-in marketing tools",
            },
          ].map((feature, index) => (
            <div
              key={index}
              className="bg-white p-6 rounded-lg text-center hover:shadow-lg transition duration-300 border border-gray-200"
            >
              <feature.icon className="mx-auto text-4xl mb-4 text-blue-500" />
              <h3 className="text-xl font-semibold mb-2 text-blue-600">
                {feature.title}
              </h3>
              <p className="text-gray-600">{feature.description}</p>
            </div>
          ))}
        </div>
      </section>

      {/* Call to Action */}
      <section className="bg-blue-100 py-16">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-3xl md:text-4xl font-bold mb-4 text-blue-700">
            Ready to Launch Your Business?
          </h2>
          <p className="text-xl mb-8 text-gray-700">
            Join SpaceStation today and take your booking system to new heights!
          </p>
          <Link
            to="/register"
            className="inline-block bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-6 rounded-full text-lg transition duration-300 shadow-lg"
          >
            Register Now
          </Link>
        </div>
      </section>

      {/* Footer */}
      <footer className="container mx-auto px-4 py-8 text-center text-gray-500">
        <p>&copy; 2024 SpaceStation. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default MainPage;
