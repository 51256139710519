import React from 'react';
import { useNavigate } from 'react-router-dom';
import { HomeIcon, ArrowLeftIcon } from 'lucide-react';

const PageNotFound: React.FC = () => {
  const navigate = useNavigate();

  const goBack = () => navigate(-1);
  const goHome = () => navigate('/');

  return (
    <div className="min-h-screen bg-gradient-to-br from-slate-50 via-slate-50 to-slate-100 flex flex-col items-center justify-center px-4">
      <div className="w-full max-w-3xl text-center space-y-8">
        <div className="relative">
          <h1 className="text-[12rem] font-extrabold text-slate-900/[0.07] leading-none select-none">
            404
          </h1>
          <div className="absolute -bottom-5 left-0 right-0">
            <div className="h-px w-40 mx-auto bg-gradient-to-r from-transparent via-slate-400 to-transparent" />
          </div>
        </div>


        <div className="space-y-3">
          <h2 className="text-3xl font-semibold text-slate-900">
            Page Not Found
          </h2>
          <p className="text-base text-slate-600 max-w-md mx-auto">
            We couldn't find the page you're looking for. The page might have been removed, 
            renamed, or is temporarily unavailable.
          </p>
        </div>

        <div className="flex items-center justify-center gap-4 pt-4">
          <button
            onClick={goBack}
            className="inline-flex items-center px-4 py-2 text-sm font-medium 
                     text-slate-700 bg-white border border-slate-300 rounded-md
                     shadow-sm hover:bg-slate-50 focus:outline-none focus:ring-2 
                     focus:ring-slate-500 focus:ring-offset-2 transition-colors"
          >
            <ArrowLeftIcon className="w-4 h-4 mr-2" />
            Go Back
          </button>

          <button
            onClick={goHome}
            className="inline-flex items-center px-4 py-2 text-sm font-medium 
                     text-white bg-slate-900 rounded-md shadow-sm 
                     hover:bg-slate-800 focus:outline-none focus:ring-2 
                     focus:ring-slate-500 focus:ring-offset-2 transition-colors"
          >
            <HomeIcon className="w-4 h-4 mr-2" />
            Return Home
          </button>
        </div>
      </div>

      <div className="absolute bottom-6 text-center">
        <p className="text-sm text-slate-500">
          If you believe this is an error, please contact{' '}
         
            support
     
        </p>
      </div>
    </div>
  );
};

export default PageNotFound;